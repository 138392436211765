export default function ({ $auth, $log }) {
  $auth.onError((error, name, endpoint) => {
    if (error.config?.data) {
      error.config.data = 'sanitised error data'
    }
    try {
      $log({
        name: 'AuthError',
        properties: {
          onErrorName: name,
          endpoint,
          message: error.message,
          stack: error.stack,
          error,
        },
      })
    } catch {
      // Fail silently
    }
  })
}
